<template>
    <div class="efg-footer-container">
        <div class="efg-footer-nav">
            <h3>NAVIGATION</h3>
            <ul class="nav">
                <li 
                    v-for="(link, i) in config.links" 
                    :key="i"
                    :class="'nav-link-' + i"
                >
                    <a :href="link.path" v-html="link.name"/> 
                </li>
            </ul>
        </div>
        <div class="efg-footer-media">
            <h3>Média</h3>
            <ul class="social-links">
                <li 
                    v-for="(media, i) in config.social" 
                    :key="i"
                >
                    <a :href="media.logo+'/#'">
                        <i :class="getLogo(media.name)"/>
                    </a> 
                </li>
            </ul>
        </div>
        <div class="efg-footer-contact">
            <h3>NOUS CONTACTER</h3>
            <app-contact :config="config"/>
        </div>
    </div>
</template>

<script>
      
    export default {
        props:["config"],
        components:{
            appContact: () => import("./sub-modules/Contact-Info-Submod.vue")
        },
        methods:{
            getLogo(name){
                switch(name){
                      case "Facebook":
                            return "fab fa-facebook-f"
                      case "Instagram":
                            return "fab fa-instagram"
                      case "Twitter":
                            return "fab fa-twitter"
                      case "Youtube":
                            return "fab fa-youtube"
                      case "LinkedIn":
                            return "fab fa-linkedin-in"
                      case "Tumblr":
                            return "fab fa-tumblr"
                      case "Reddit":
                            return "fab fa-reddit"
                      case "Pinterest":
                            return "fab fa-pinterest"
                      default:
                            return ""
                }
            }
        },
        mounted(){
            if(!this.config.links){
                this.config["links"] = []
            }
            if(!this.config.social){
                this.config["social"] = []
            }
        }
    }
      
</script>